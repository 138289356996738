<template>
  <div class="top-nav">
    <h1 style="display: none">Ruijie</h1>
    <carousel
      :dataList="bannerList"
      v-if="bannerList.length > 0"
      style="maxi-height: 830px"
    />
    <!-- <div class="banner-cont">
      <div style="color: white" class="wenzi">Innovation Beyond Networks</div>
    </div> -->

    <hot-product :dataList="hotList" :smList="smList" />
    <product-list :dataList="cateType" />
    <solution :solutionList="soluList" />
    <about :dataList="advantageList" />
    <news
      :classfyList="newsClassylist"
      :newsListHxz="newsListHxz"
    />
    <pre-sale />

    <el-backtop class="backtop"></el-backtop>
  </div>
</template>

<script>
import Carousel from "@/components/index/carousel";
import HotProduct from "@/components/product/hotProduct";
import ProductList from "@/components/product/productList";
import Solution from "@/components/index/solution";
import About from "@/components/index/about";
import News from "@/components/index/news";
import PreSale from "@/components/index/preSale";
if (process.browser) {
  // 在这里根据环境引入wow.js
  var { WOW } = require("wowjs");
}

import { getNewsClassfyData, getNewsList } from "@/api/news";
import { getBanner, getHotProduct } from "@/api/index";
import { solutionList, productType, ruijieAdvantage } from "@/api/api.js";

import reqApi from "@/api/apiUrl";

export default {
  scrollToTop: true,
  data() {
    return {
      newsClassylist: [], //分类新闻列表
      bannerList: [], //首页轮播图
      hotList: [], //
      soluList: [], //解决方案列表
      cateType: [], //产品分类
      advantageList: [], //锐捷优势
      smList: [],
      newsList: [],
      newsListHxz:[]
    };
  },
  components: {
    News,
    HotProduct,
    ProductList,
    Solution,
    About,
    Carousel,
    PreSale,
  },
  //设置seo
  head: {
    htmlAttrs: {
      lang: "zh-CN",
    },
    title:
      "Ruijie Networks：スイッチや無線LAN、ルーターなどのネットワーク機器とソリューションプロバイダーです",
    meta: [
      {
        hid: "description",
        name: "description",
        content:
          "Ruijie Networksは業界をリードするICTインフラ及び業界ソリューションプロバイダーです。ネットワーク機器の全製品ラインナップとネットワークソリューションを提供するメーカーであり、スイッチやワイヤレスやルーターなどは主要製品です。Ruijie Networksはグローバル50以上の国と地域で業務を展開しております。",
      },
      {
        hid: "keywords",
        name: "keywords",
        content:
          "ruijie japan,ruijie ルーター,ruijie データセンター,ruijie スイッチ,ruijie クラウド管理,ruijie アクセスポイント,ruijie ワイヤレス",
      },
    ],
    link:[
      {rel:"alternate",hreflang:"zh",href:"https://www.ruijie.com.cn"},
      {rel:"alternate",hreflang:"en",href:"https://www.ruijienetworks.com/"},
      {rel:"alternate",hreflang:"ja",href:"https://www.ruijie.co.jp/"},
    ]
  },
  mounted() {
    this.allFunc();
  },

  created() {},

  async asyncData({ query, $axios }) {
    const { data } = await $axios.get(reqApi.getNewsClassfyData).then();

    let obj = { id: "all", name: "すべて" };
    let newsData = data.data;
    newsData.unshift(obj);
    let { data: newsListData } = await $axios
      // .get(reqApi.getNewsList + "?limit=10&page=1")
      .get(reqApi.getNewsList) //请求所有的数据
      .then();
    newsListData.data.list.forEach((item) => {
      item.labelId="all"
      if (item.labelName) {
        item.tag = item.labelName.split(",");
      }
    });
    // let newsListObj = { "all": newsListData.data.list };
    let newsListArr = newsListData.data.list;
    let banner = await $axios.get(reqApi.getBanner + "20").then();
    let hotList = await $axios.get(reqApi.getHotProduct).then();
    for (let i = 0; i < hotList.data.data.length; i++) {
      if (hotList.data.data[i].labelValueIds) {
        hotList.data.data[i].label = hotList.data.data[i].labelValueIds.split(",");
      } else {
        hotList.data.data[i].label = [];
      }
    }
    console.log('hotList', hotList.data.data);
    //处理数据
    let index = 0;
    let newArray = [];
    while (index < hotList.data.data.length) {
      newArray.push(hotList.data.data.slice(index, (index += 3)));
    }
    let cateList = await $axios.get(reqApi.getProductType).then();
    let arr = [];
    if (cateList.data.data.length > 3) {
      arr = arr.slice(0, 3);
    } else {
      arr = cateList.data.data;
    }
    arr.forEach((item,index)=>{
      if(index==0){
        item.link='/products/switches';
      }else if(index==1){
        item.link='/products/wireless';
      }else if(index==2){
        item.link='/products/routers';
      }
    })

    let soluList = await $axios
      .get(reqApi.getSolutionList + "?limit=2&page=1&isIndex=1")
      .then();
    // console.log(soluList.data.data.list,'solutions');
    /* if (soluList.data.data.list.length > 0) {
      soluList.data.data.list.forEach((item, index) => {
        if (index == 0) {
          item["jumpUrlName"] =
            "/solutions/japan-cloud-system-solution_431383103847006208.html";
        } else if (index == 1) {
          item["jumpUrlName"] =
            "/solutions/100g-400g-ena-data-center-network-solution_431384761587204096.html";
        }
      });
    } */

    let ruijieStroge = await $axios.get(reqApi.getRuijiStorge).then();

    return {
      newsClassylist: newsData,
      newsList: newsListData.data.list,
      bannerList: banner.data.data || [],
      smList: hotList.data.data || [],
      hotList: newArray,
      cateType: arr,
      soluList: soluList.data.data.list || [],
      advantageList: ruijieStroge.data.data || [],
      newsListHxz: newsListArr || []
    };
  },

  methods: {
    async allFunc() {
      // await this.getData();
      // await this.getBanner();
      // await this.getHot();
      await this.getSolution();
      // await this.getProductCate();
      // await this.getRuijieAdvantage();
      // window.scrollTo(
      //   {
      //     top: 0,
      //   },
      //   100
      // );
      this.getAllList();
    },
    // 在首页全部请求
    getAllList() {
      this.newsClassylist.slice(1).forEach((item) => {
        getNewsList({
          limit: 5,
          page: 1,
          classId: item.id,
          isIndex: 1,
        })
          .then((res) => {
            res.data.list.forEach((rItem) => {
              rItem.labelId=item.id
              if (rItem.labelName) {
                rItem.tag = rItem.labelName.split(",");
              }
            });
            this.newsListHxz=this.newsListHxz.concat(res.data.list);
          })
          .catch((e) => {});
      });
    },
    getChildData(res) {
      getNewsList({
        limit: 5,
        page: 1,
        classId: this.newsClassylist[res].id,
        isIndex: 1,
      })
        .then((res) => {
          res.data.list.forEach((item) => {
            if (item.labelName) {
              item.tag = item.labelName.split(",");
            }
          });
          this.newsList = res.data.list;
        })
        .catch((e) => {});
    },
    async getRuijieAdvantage() {
      await ruijieAdvantage().then((res) => {
        if (res.data) {
          res.data.forEach((item, index) => {
            //只显示三个
            if (index < 3) {
              this.advantageList.push(item);
            }
          });
        }
      });
    },

    //获取首页产品
    async getHot() {
      await getHotProduct()
        .then((res) => {
          if (res.data) {
            this.smList = res.data;
            this.hotList = this.group(res.data, 3);
          }
        })
        .catch((e) => {});
    },

    //解决方案
    async getSolution() {
      await solutionList({
        limit: 2,
        page: 1,
        isIndex: 1,
      }).then((res) => {
        console.log(res.data.list,'soutions');
        this.soluList = res.data.list;
        debugger;
      });
    },
    //获取产品分类
    async getProductCate() {
      await productType().then((res) => {
        if (res.data) {
          res.data.forEach((item, index) => {
            //只显示三个
            if (index < 3) {
              this.cateType.push(item);
            }
          });
        }
      });
    },

    //获取新闻分类列表
    async getData() {
      await getNewsClassfyData()
        .then((res) => {
          let obj = { id: "", name: "すべて" };
          if (res.data) {
            this.newsClassylist = res.data;
          }
          this.newsClassylist.unshift(obj);

          // setTimeout(() => this.$nuxt.$loading.finish(), 1000);
        })
        .catch((e) => {});
    },
    //获取轮播图
    async getBanner() {
      await getBanner({ type: 20 }).then((res) => {
        //20是首页
        if (res.data) {
          this.bannerList = res.data;
        }
      });
    },
    //分割数组
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-backtop {
  background: rgb(216, 216, 216);
}

::v-deep.el-icon-caret-top:before {
  color: #ffffff;
}
.backtop {
  color: #ffffff;
}
.banner-cont {
  .wenzi {
    font-size: 55px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  position: absolute;
  left: 0;
  top: 500px;
  z-index: 3;
  width: 100%;
  text-align: center;
}
@media (max-width: 750px) {
  .banner-cont {
    top: 145px !important;
    .wenzi {
      font-size: 18px;
    }
  }
}
</style>
