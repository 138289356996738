<template>
  <div class="hot-div container">
    <!-- 屏幕小于750显示轮播 -->
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      img-height="830px"
      style="width: 100% !important"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      v-if="smList.length > 1 && screenWidth <= 750"
      class="d-md-none d-block"
    >
      <div v-for="item in smList"  :key="item.id">
        <b-carousel-slide class="banner">
          <template #img>
            <!-- <b-link
              :to="{
                name: 'products-urlName',
                params: {
                  urlName: `${item.urlName}` + '_p' + `${item.id}.html`,
                },
              }"
            > -->
             <b-link :href="`/products/${item.urlName}_p${item.id}.html`">
              <b-card class="overflow-hidden my-2">
                <b-card-body class="">
                  <div class="img-div">
                    <div class="hot-tag-div">
                      <div class="hot-tag" v-show="item.isHot == 1">HOT</div>
                      <div class="hot-tag" v-show="item.isNew == 1">New</div>
                      <div class="hot-tag" v-show="item.isRecommend == 1">
                        RECOMMEND
                      </div>
                    </div>
                    <!-- <el-image :src="item.imgUrl" fit="cover" /> -->
                     <b-img class="el-image" :src="item.imgUrl" ></b-img>

                  </div>

                  <div class="item-div">
                    <div
                      class="
                        card-body-text
                        text-muted text-justify
                        product-title
                        diandiandian
                      "
                    >
                      {{ item.name }}
                    </div>
                    <div class="product-subtitle threediandiandian">
                      {{ item.remarks }}
                    </div>
                    <div class="product-tag">
                      <span v-for="item2 in item.label" :key="item2">
                        {{ item2 }}
                      </span>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-link>
          </template>
        </b-carousel-slide>
      </div>
    </b-carousel>

    <!-- 屏幕大于750显示轮播 -->
    <el-carousel
      height="649px"
      :interval="5000"
      class="d-md-block d-none"
      v-if="screenWidth > 750"
    >
      <el-carousel-item v-for="item in dataList" :key="item.id">
        <b-row class="mx-0" style="height: 649px; padding: 0 10px">
          <b-col lg="4" md="6" sm="12" v-for="item1 in item" :key="item1.id">
            <!-- <b-link
              :to="{
                name: 'products-urlName',
                params: {
                  urlName: `${item1.urlName}` + '_p' + `${item1.id}.html`,
                },
              }"
            > -->
             <b-link :href="`/products/${item1.urlName}_p${item1.id}.html`">
              <b-card class="overflow-hidden my-2">
                <b-row no-gutters>
                  <b-col cols="12">
                    <b-card-body class="">
                      <div class="img-div">
                        <div class="hot-tag-div">
                          <div class="hot-tag" v-show="item1.isHot == 1">
                            HOT
                          </div>
                          <div class="hot-tag" v-show="item1.isNew == 1">
                            New
                          </div>
                          <div class="hot-tag" v-show="item1.isRecommend == 1">
                            RECOMMEND
                          </div>
                        </div>

                        <!-- <el-image :src="item1.imgUrl" /> -->
                         <b-img-lazy class="el-image" :title="item1.name" :src="item1.imgUrl" :alt="item1.name"></b-img-lazy>
                      </div>

                      <div class="item-div">
                        <div
                          class="
                            card-body-text
                            text-muted text-justify
                            product-title
                            diandiandian
                          "
                        >
                          {{ item1.name }}
                        </div>
                        <div class="product-subtitle threediandiandian">
                          {{ item1.remarks }}
                        </div>
                        <div class="product-tag">
                          <span v-for="item2 in item1.label" :key="item2">
                            {{ item2 }}
                          </span>
                        </div>
                      </div>
                    </b-card-body>
                  </b-col>
                </b-row>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    dataList: {
      type: Array,
    },
    smList: {
      type: Array,
    },
  },
  components: {},
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },

  computed: {
    ...mapMutations(["setScreenWidth"]),
    ...mapState(["screenWidth"]),
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit("setScreenWidth", document.body.clientWidth);
      })();
    };
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
a {
  /* color: #007bff; */
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $nav-black-color;
  &:hover {
    text-decoration: none;
  }
}
@media (max-width: 750px) {
  .card:first-child {
    margin-left: 0 !important;
  }
  .el-carousel__item {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .hot-div {
    margin-top: 20px !important;
    .lg-mg {
      display: none !important;
    }
    .sm-mg {
      display: flex !important;
    }
    .product-subtitle {
      //height: 144px !important;
    }
    .twodiandiandian {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }
  }
  //.threediandiandian {
  //  display: -webkit-box;
  //  -webkit-box-orient: vertical;
  //  -webkit-line-clamp: 6;
  //  overflow: hidden;
  //}

  .col-sm-12 {
    padding: 0 !important;
  }
  .carousel-control-prev {
    display: none !important;
  }
  .carousel-control-next {
    display: none !important;
  }
}
.card-body {
  height: 630px !important;
}

.item-div {
  //height: 154px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
::v-deep .el-carousel--horizontal {
  width: 100%;
}
::v-deep .el-carousel__item {
  width: 90%;
  margin-left: 5%;
  display: block !important;
}
::v-deep .el-carousel__arrow:hover {
  background-color: rgba(31, 45, 61, 0.11);
}
::v-deep button:focus {
  outline: none;
}

::v-deep .img-thumbnail {
  border: none;
  padding: 63px 0 76px 0;
  background: none;
}

::v-deep .card {
  //height: 649px;
  border: none;
}
::v-deep .card:first-child {
  //height: 649px;
  margin-left: 5px;
}

::v-deep .card-body {
  padding: 0;
  background: #f9f9f9;
  text-align: center;
}

.card:hover {
  background: #ffffff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.11);
  transition: all 0.3s;
  .img-div {
    // background-image: url("~@/static/img/product/product-bg.png");
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-size: cover;
    // background-color: #999;
  }
}

.img-div {
  height: 408px;
  display: flex;
  justify-content: center;
  align-items: center;
  .el-image {
    margin: 63px 60px 76px 60px;
  }
}

.hot-div {
  background: #fff;
  margin: 80px auto;
  height: auto;
  display: flex;
  justify-content: center;

  .hot-tag-div {
    position: absolute;
    top: 12px;
    left: 12px;
    height: 18px;
    z-index: 10;
    display: flex;
  }
  .hot-tag {
    background: rgba(230, 0, 51, 0.05);
    margin-right: 10px;
    font-family: Source Han Sans JP;
    font-weight: 400;
    font-size: 12px;
    color: #da0030;
    padding: 0 10px;
  }

  .product-title {
    text-align: center !important;
    padding: 0 37px;
    //height: 66px;
    font-family: Source Han Sans JP;
    font-weight: 400;
    font-size: 20px;
    color: #292929;
    letter-spacing: 0.6px;
    line-height: 33px;
    &:hover {
      color: $red-color !important;
      cursor: pointer;
      transition: all 0.3s;
    }
  }

  .product-subtitle {
    height: 72px;
    font-family: Source Han Sans JP;
    font-weight: 400;
    font-size: 16px;
    color: $nav-defalut-color;
    letter-spacing: 0.4px;
    text-align: left;
    padding: 0 37px;
    margin-top: 12px;
  }
  .lg-mg {
    // display: flex;
  }
  .sm-mg {
    display: none;
  }
}
.product-tag {
  padding: 0 37px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  span {
    display: inline-block;
    height: 22px;
    //line-height: 22px;
    padding: 0 8px;
    border: 1px solid $nav-defalut-color;
    font-size: 12px;
    color: $nav-defalut-color;
    margin: 0 8px 8px 0;
  }
}
</style>
