<template>
  <div class="number-grow-warp">
    <span
      ref="numberGrow"
      :data-time="time"
      class="number-grow"
      :data-value="value"
      @mouseover="changeMask()"
      >0</span
    >
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: 2,
    },
    value: {
      type: Number,
      default: 1000,
    },
    fixedValue: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    numberGrow(ele, fixedValue) {
      let _this = this;

      let step = (_this.value * 10) / (_this.time * 1000);
      let current = 0;
      let start = 0;
      let t = setInterval(function () {
        start += step;
        if (start > _this.value) {
          clearInterval(t);
          start = _this.value;
          t = null;
        }
        if (current === start) {
          return;
        }
        current = start;
        ele.innerHTML = current.toFixed(fixedValue);
      }, 10);
    },
    changeMask() {},
  },
  mounted() {
    this.numberGrow(this.$refs.numberGrow, this.fixedValue);
  },
};
</script>

<style>
.number-grow-warp {
  transform: translateZ(0);
}
.number-grow {
  height: 90px;
  color: #395a84;
  font-family: Source Han Sans JP;
  font-size: 48px;
}
@media (max-width: 750px) {
  .number-grow {
    font-size: 42px;
  }
}
</style>
