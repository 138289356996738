<template>
  <my-slot :title="title" :showBgColor="true">
    <b-row class="mx-0">
      <b-col
        lg="6"
        md="6"
        sm="12"
        v-for="item of dataList"
        :key="item.id"
        class="wow"
      >
        <b-link :href="item.link">
          <b-card img-top class="">
            <!-- <el-image style="width: 100%;" :src="item.imageUrl || defaultImg">

          </el-image> -->
            <div style="display: flex; justify-content: center">
              <b-img
                :src="item.imageUrl || defaultImg"
                :alt="item.name"
                :title="item.name"
                fluid
                style="max-width: 70%"
              ></b-img>
            </div>

            <h3 class="mx-auto text-truncate list-title diandiandian">
              {{ item.name }}
            </h3>
            <div class="mx-auto text-truncate detail">
              <div class="circle">
                <i class="el-icon-arrow-right"></i>
              </div>
              <span>詳細の表示</span>
            </div>
            <!-- 蒙版 -->
            <div class="mask">
              <div class="esc">
                {{ item.remarks }}
              </div>
            </div>
          </b-card>
        </b-link>
      </b-col>

      <b-col lg="6" md="6" sm="12" class="wow">
        <nuxt-link
          to="/solutions/japan-cloud-system-solution_431383103847006208.html"
        >
          <b-card img-top class="">
            <!-- <el-image style="width: 100%" fit="cover" :src="defaultImg">
            </el-image> -->
            <div style="display: flex; justify-content: center">
              <b-img
                :src="defaultImg"
                alt="Ruijie JaCS(Ruijie Japan Cloud System)"
                title="Ruijie JaCS(Ruijie Japan Cloud System)"
                fluid
                style="max-width: 70%"
              ></b-img>
            </div>
            <h3 class="mx-auto text-truncate list-title diandiandian">
              Ruijie JaCS(Ruijie Japan Cloud System)
            </h3>
            <div class="mx-auto text-truncate detail">
              <div class="circle">
                <i class="el-icon-arrow-right icon-color"></i>
              </div>
              <span>詳細の表示</span>
            </div>
            <!-- 蒙版 -->
            <div class="mask">
              <div class="esc">
                クラウドからの一元管理に対応し、遠隔管理を実現、可視化された機器の利用状況分析、柔軟な機器メンテナンスが容易に可能
              </div>
            </div>
          </b-card>
        </nuxt-link>
      </b-col>
    </b-row>

    <!-- <div class="show-more" >
      <a class="more" href="/products">
        その他の製品の表示 <i class="el-icon-arrow-right" style="padding-left: 18px"></i>
      </a>
    </div> -->
  </my-slot>
</template>

<script>
import MySlot from "@/components/slot/index";

export default {
  components: {
    MySlot,
  },
  props: {
    dataList: {
      type: Array,
    },
  },
  data() {
    return {
      title: "製品カテゴリー",
      defaultImg: require("~/static/img/fangan.jpg"),
    };
  },
  methods: {
    goProduct() {
      this.$router.push("/products");
    },
    go(item) {
      this.$router.push({
        path: "/products",
        query: {
          cateId: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@media (max-width: 750px) {
  .col-sm-12 {
    padding: 0 15px !important;
  }
}
a {
  /* color: #007bff; */
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $nav-black-color;
  &:hover {
    text-decoration: none;
  }
}
.card-body {
  padding: 0;
}
.card {
  border: none;
  background: #ffffff;
  margin-bottom: 32px;
  width: 100%;
  .mask {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 147px;
    display: none;
    align-items: center;
    text-align: left;
    background: rgba(#000000, 0.13);
    padding: 24px 43px;
    color: #ffffff;
    .esc:hover {
      color: $red-color !important;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
}
.more {
  background: $red-color;
  border: 1px solid $red-color;

  color: #ffffff !important;
}
.card:hover {
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.11);
  transition: all 0.3s;
  .mask {
    display: flex !important;
  }
}
.card-body-text {
  max-height: 102px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.list-title {
  padding: 19px 41px 0 41px;
  //height: 36px;
  font-family: Source Han Sans JP;
  font-weight: 500;
  font-size: 20px;
  color: #292929;
  letter-spacing: 0.4px;
  line-height: 36px;
  &:hover {
    color: $red-color !important;
    transition: all 0.3s;
    cursor: pointer;
  }
}
.detail {
  margin: 25px 0 30px 0;
  padding: 0 41px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7d7d7d;
  letter-spacing: 0.17px;
  .circle {
    height: 32px;
    width: 32px;
    border: 1px solid #8d8d8d;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    color: #8d8d8d;
    margin-right: 13px;
  }
  &:hover {
    cursor: pointer;
    color: $red-color;
    .circle {
      border: 1px solid $red-color;
    }
    .el-icon-arrow-right {
      color: $red-color !important;
    }
  }
}
</style>
