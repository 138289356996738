<template>
  <section class="bg">
    <div class="container center">
      <div class="pre-esc">
        購入前の営業的な質問とOEM・ODM・チャネルパートナーを希望のお問い合わせについてはこちらから
      </div>
      <div class="show-more" style="margin-top: 30px">
        <b-link class="more" to="/sales" >
          お問い合わせ
          <i class="el-icon-arrow-right" style="padding-left: 18px"></i>
        </b-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";

import NumberGrow from "@/components/index/numberGrow";

export default {
  components: {
    NumberGrow,
  },
  data() {
    return {};
  },

  methods: {
    ...mapMutations(["setSubNavIndex"]),

    gopreSale() {
      this.$router.push("/sales");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
a {
  /* color: #007bff; */
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    text-decoration: none;
    // background: $red-color;
    border: 1px solid $gray-color;
    // color:#ffffff !important;
  }
}
@media (max-width: 768px) {
  .container {
    height: 400px !important;
  }
}
.container {
  height: 300px;
  .pre-esc {
    font-family: Source Han Sans JP;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 0.4px;
    text-align: center;
    // padding-top: 57px;
  }
}

.bg {
  background-image: url("~@/static/img/about/pre-sale.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.more {
  background: white;
  color: $nav-black-color !important;
  border: none;
  &:hover {
    cursor: pointer;
    opacity: 0.9 !important;
    color:#E60033 !important;
  }
}
</style>
