<template>
  <my-slot :title="title" style="background: #ffffff" :showBgColor="true">
    <b-row class="mx-0">
      <b-col
        lg="6"
        md="6"
        sm="12"
        v-for="item of solutionList"
        :key="item.id"
        class="wow"
      >
        <nuxt-link :to="item.webUrl" :title="item.name">
          <b-card img-top class="">
            <b-card-img
              class="mx-auto d-block rounded thumbnail"
              :src="item.appImageUrl"
              id="appImgShow"
              :img-alt="item.name"
              :title="item.name"
            >
            </b-card-img>
            <b-card-img
              class="mx-auto d-block rounded thumbnail"
              :src="item.imageUrl"
              id="webImgShow"
              :img-alt="item.name"
              :title="item.name"
            >
            </b-card-img>
            <!--未有蒙版显示
          -->
            <h3 class="nomasktitle twodiandiandian">
              {{ item.name }}
            </h3>

            <!-- 蒙版 -->
            <div class="mask">
              <!-- <div class="title twodiandiandian">
                {{ item.name }}
              </div> -->
              <div class="subtitle twodiandiandian">
                {{ item.summary }}
              </div>
              <div class="plan-btn">詳細を見る</div>
            </div>
          </b-card>
        </nuxt-link>
      </b-col>
    </b-row>

    <!-- <div class="show-more" @click="jumpAll()">
      <div class="more">
        その他のシナリオの表示
        <i class="el-icon-arrow-right" style="padding-left: 18px"></i>
      </div>
    </div> -->
  </my-slot>
</template>

<script>
import MySlot from "@/components/slot/index";

export default {
  components: {
    MySlot,
  },
  props: {
    solutionList: {
      type: Array,
    },
  },
  data() {
    return {
      title: "ソリューション",
    };
  },
  methods: {
    href(url) {
      window.location.href = url;
    },
    jumpAll() {
      this.$router.push("/solutions");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

@media (max-width: 750px) {
  .col-sm-12{
    padding: 0 15px !important;
  }
}

.card-body {
  padding: 0;
}

.nomasktitle {
  position: absolute;
  left: 42px;
  right: 42px;
  bottom: 40px;
  height: 36px;
  font-family: Source Han Sans JP;
  font-weight: Bold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0.6px;
  display: flex;
  z-index: 10;
   &:hover {
       transition: all 0.3s;
      color: $red-color !important;
      cursor: pointer;
   }
}

.card {
  border: none;
  background: #ffffff;
  margin-bottom: 32px;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    text-align: left;
    background: rgba(#000000, 0.13);
    color: #ffffff;

    .title {
      position: absolute;
      bottom: 60px;
      left: 37px;
      right: 37px;
      display: flex;
      height: 72px;
      line-height: 36px;
      font-weight: Bold;
      font-size: 24px;
      color: #ffffff;
      letter-spacing: 0.6px;
    }

    .subtitle {
      position: absolute;
      bottom: 100px;
      left: 37px;
      right: 37px;
      display: flex;
      align-items: center;
    }

    .plan-btn {
      position: absolute;
      bottom: 37px;
      left: 37px;
      height: 42px;
      line-height: 42px;
      display: inline-block;
      align-items: center;
      background: #e60033;
      border-radius: 4px;
      padding: 0 18px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ffffff;
      &:hover {
        cursor: pointer;
        background: #ffffff;
        color: $red-color;
        transition: all 0.3s;
      }
    }

    .title:hover {
      transition: all 0.3s;
      color: $red-color !important;
      cursor: pointer;
    }
  }
}

.card:hover {
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.11);
  transition: all 0.3s;

  .mask {
    z-index: 1;
    display: inline-block !important;
  }

  .nomasktitle {
    // display: none;
     bottom: 100px;
      left: 37px;
      right: 37px;
  }
}

.card-body-text {
  max-height: 102px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.list-title {
  padding: 19px 41px 0 41px;
  //height: 36px;
  font-family: Source Han Sans JP;
  font-weight: 500;
  font-size: 24px;
  color: #292929;
  letter-spacing: 0.4px;
  line-height: 36px;

  &:hover {
    color: $red-color !important;
    transition: all 0.3s;
  }
}

.detail {
  margin: 25px 0 30px 0;
  padding: 0 41px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7d7d7d;
  letter-spacing: 0.17px;

  .circle {
    height: 32px;
    width: 32px;
    border: 1px solid #8d8d8d;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    color: #8d8d8d;
    margin-right: 13px;
  }
}

@media screen and (min-width: 600px) and (max-width: 4000px) {
  #appImgShow {
    display: none !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  #webImgShow {
    display: none !important;
  }
}
a:hover {
    text-decoration: none;
}
</style>
