<template>
  <section class="text-light container concat">
    <!--pc端-->
    <el-menu class="el-menu-demo d-md-block d-none" mode="horizontal" v-if="screenWidth>750">
      <el-menu-item
        class="col-lg-2 col-md-6 col-sm-6"
        v-for="(item) in classfyList"
        :key="item.id"
        :class="activeIndex == item.id ? 'isactive' : ''"
        @click="handleSelect(item.id)"
        >{{ item.name }}</el-menu-item
      >
    </el-menu>
    <div class="news-list d-md-block d-none"  v-if="screenWidth>750">
      <b-link
        :to="{
          name: 'news-urlName',
          params: { urlName: `${item.urlName}` + '_' + `${item.id}.html` },
        }"
        class="news-item"
        v-for="(item,index) of newsListHxz"
        :key="index"
        v-show="item.labelId===activeIndex"
      >
        <span class="span1">{{ item.addTime }}</span>
        <span v-for="tag in item.tag" :key="tag">
          <span class="span3 diandiandian" v-if="tag">
            {{ tag }}
          </span>
        </span>
        <span class="span2 twodiandiandian">{{ item.name }}</span>

        <img src="~@/static/img/public/right-arrow.png" />
      </b-link>
    </div>

    <!--h5-->
    <el-menu
      class="el-menu-demo d-md-none d-block"
      mode="horizontal"
      v-if="screenWidth <= 750"
    >
      <el-menu-item
        class="col-lg-2 col-md-6 col-sm-6 isactive"
        @click="handleSelect('all')"
        v-if="classfyList.length > 0"
        >{{ classfyList[0].name }}</el-menu-item
      >
    </el-menu>
    <div class="d-md-none d-block sm-news" v-if="screenWidth <= 750">
      <b-link
        :to="{
          name: 'news-urlName',
          params: { urlName: `${item.urlName}` + '_' + `${item.id}.html` },
        }"
        class="item"
        v-for="(item, ind) of newsListHxz"
        :key="ind"
        v-show="item.labelId===activeIndex"
      >
        <div class="left-div">
          <div class="div1">
            <span class="span1">{{ item.addTime }}</span>
            <span v-for="tag in item.tag" :key="tag">
              <span class="span3 diandiandian" v-if="tag">
                {{ tag }}
              </span>
            </span>
          </div>
          <span class="span2 twodiandiandian">{{ item.name }}</span>
        </div>
        <img src="~@/static/img/public/right-arrow.png" />
      </b-link>
    </div>

    <div class="show-more" style="margin-top: 60px">
      <b-link to="/news" class="more">
        すべてのニュースを見る
        <i class="el-icon-arrow-right" style="padding-left: 18px"></i>
      </b-link>
    </div>
  </section>
</template>

<script>
import MySlot from "@/components/slot/index";
import { getNewsList } from "@/api/news";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MySlot,
  },
  props: {
    classfyList: {
      type: Array,
      default: [],
    },
    newsListHxz: {
      type: Array,
      default: {},
    },
  },
  data() {
    return {
      newsData:this.newsListHxz["all"],
      activeIndex: "all",
    };
  },
  computed: {
    ...mapMutations(["setScreenWidth"]),
    ...mapState(["screenWidth"]),
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit("setScreenWidth", document.body.clientWidth);
      })();
    };
  },
  watch: {
    classfyList(val) {
      this.getData();
    },
  },
  methods: {
    handleClick(tab, event) {
    },
    handleSelect(idx) {
      this.activeIndex = idx;
      this.newsData=this.newsListHxz[idx]
      // this.getData();
      // this.$emit("childMethod", idx); // 方法1
    },
    getData() {
      getNewsList({
        limit: 5,
        page: 1,
        classId: this.classfyList[this.activeIndex].id,
        isIndex: 1,
      })
        .then((res) => {
          res.data.list.forEach((item) => {
            if (item.labelName) {
              item.tag = item.labelName.split(",");
            }
          });
          // this.newsList = [...res.data.list, ...this.newsList];
          this.newsList = res.data.list;
        })
        .catch((e) => {});
    },
    //跳转详情
    goDetail(item) {
      this.$router.push({
        path: "/news/newsDetail",
        query: {
          id: item.id,
        },
      });
    },
    //跳转更多
    goMore() {
      this.$router.push({
        path: "/news",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
a {
  /* color: #007bff; */
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    text-decoration: none;
    // background: $red-color;
    color: #292929 !important;
  }
}
.el-menu.el-menu--horizontal {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.container {
  padding: 80px 0;
}
::v-deep .el-menu-item.is-active {
  border-bottom: none;
}
.el-menu-item {
  text-align: center;
  color: $nav-black-color;
  font-size: 18px;
  letter-spacing: 0.6px;
}
.isactive {
  border-bottom: 3px solid $red-color !important;
  color: $red-color !important;
  font-size: 18px;
  letter-spacing: 0.6px;
}
.el-menu-demo {
  display: flex;
  flex-wrap: wrap;
}
.news-list {
  margin-top: 64px;
  padding: 0 10px;
  .news-item {
    display: flex;
    justify-content: flex-start;
    line-height: 24px;
    font-family: Source Han Sans JP;
    font-weight: 400;
    font-size: 16px;
    color: $nav-black-color;
    padding: 24px 0;
    align-items: center;
    border-bottom: 1px dashed $gray-color;
    &:hover {
      transition: all 0.3s;
      cursor: pointer;
      .span2 {
        text-decoration: underline;
      }
    }
    .span1 {
      width: 140px;
      font-size: 16px;
      color: $nav-defalut-color;
    }
    .span2 {
      line-height: 24px;
      flex: 1;
      margin-left: 75px;
      &:hover {
        text-decoration: underline;
        transition: all 0.3s;
        cursor: pointer;
      }
    }
    img {
      width: 8px;
      height: 16px;
      margin-right: 30px;
    }
    .span3 {
      width: 180px;
      height: 28px;
      opacity: 0.79;
      border: 2px solid $red-color;
      border-radius: 4px;
      padding: 0;
      font-size: 16px;
      color: #292929;
      // width: 180px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 21px;

      &:hover {
        // opacity: 0.79;
        // border: 2px solid #517351;
        // border-radius: 4px;
        // color: #517351;
        // cursor: pointer;
      }
    }
  }
}
@media (max-width: 750px) {
  .col-sm-6 {
    width: 50% !important;
    padding: 0 15px !important;
  }
  .el-menu.el-menu--horizontal {
    justify-content: flex-start;
  }
}
.container {
  padding-top: 20px !important;
}
.sm-news {
  margin-top: 34px;
  .item {
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    font-family: Source Han Sans JP;
    font-weight: 400;
    font-size: 16px;
    color: $nav-black-color;
    padding: 24px 0;
    align-items: center;
    border-bottom: 1px dashed $gray-color;
    &:hover {
      transition: all 0.3s;
      cursor: pointer;
      .span2 {
        text-decoration: underline;
      }
    }
    .left-div {
      padding-right: 10px;
    }
    .div1 {
      display: flex;
      justify-content: flex-start;
    }
    .span1 {
      /*width: 140px;*/
      margin-right: 20px;
      font-size: 16px;
      color: $nav-defalut-color;
    }
    .span2 {
      line-height: 24px;
      flex: 1;
      margin-top: 10px;
      &:hover {
        text-decoration: underline;
        transition: all 0.3s;
        cursor: pointer;
      }
    }
    img {
      width: 8px;
      height: 16px;
      margin-right: 10px;
    }
    .span3 {
      width: 180px;
      height: 28px;
      opacity: 0.79;
      border: 2px solid $red-color;
      border-radius: 4px;
      padding: 0;
      font-size: 16px;
      color: #292929;
      // width: 180px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 21px;

      &:hover {
        // opacity: 0.79;
        // border: 2px solid #517351;
        // border-radius: 4px;
        // color: #517351;
        // cursor: pointer;
      }
    }
  }
}
</style>
