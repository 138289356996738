import service from '@/plugins/service'

// 获取新闻分类列表
export const getNewsClassfyData = () => {
  return service({
    url: '/api/news/list',
    method: 'GET'
  })
}

// 获取新闻分页列表
export const getNewsList = (query) => {
    return service({
      url: '/api/news/pageList',
      method: 'GET',
      params: query
    })
  }

  // 获取新闻分页列表
export const getNewsInfo = (query) => {
    return service({
      url: '/api/news/info/' + query.id,
      method: 'GET',
    })
  }