<template>
  <section class="bg">
    <div class="container" @mouseover="changeMask()">
      <h2 class="main-title">Innovation Beyond Networks</h2>
      <b-row class="number-div">
        <b-col lg="2" md="4" sm="4" class="wow number-info">
          <!--         <div class="title "></div>-->
          <div class="number">
            <NumberGrow :value="90" class="title"></NumberGrow><span>+</span>
          </div>
          <div class="number-span">グローバル拠点</div>
        </b-col>
        <b-col lg="2" md="4" sm="4" class="wow number-info">
          <div class="number">
            <NumberGrow :value="8000" class="title"></NumberGrow><span>+</span>
          </div>

          <div class="number-span">従業員</div>
        </b-col>

        <b-col lg="2" md="4" sm="4" class="wow number-info">
          <div class="number">
            <NumberGrow :value="55" class="title"></NumberGrow><span>%</span>
          </div>
          <div class="number-span">開発員比率</div>
        </b-col>
        <b-col lg="2" md="4" sm="4" class="wow number-info">
          <div class="number">
            <NumberGrow :value="8" class="title"></NumberGrow>
          </div>
          <div class="number-span">R&Dセンター</div>
        </b-col>
        <b-col lg="2" md="4" sm="4" class="wow number-info">
          <div class="number">
            <NumberGrow :value="19.83" :fixed-value="2" class="title"></NumberGrow><span>%</span>
          </div>
          <div class="number-span">研究開発投資売上比率</div>
        </b-col>
        <b-col lg="2" md="4" sm="4" class="wow number-info">
          <div class="number">
            <NumberGrow :value="2800" class="title"></NumberGrow><span>+</span>
          </div>
          <div class="number-span">特許出願件数</div>
        </b-col>
        <!-- <b-col lg="2" md="4" sm="6" class="wow number-info">
          <div class="number">
            <NumberGrow :value="20000" class="title"></NumberGrow><span>+</span>
          </div>
          <div class="number-span">法人事業パートナー</div>
        </b-col> -->
      </b-row>

      <!-- pc -->
      <b-row
        class="mx-0 d-md-block d-none"
        style="margin-top: 40px"
        v-if="screenWidth > 750"
      >
        <div style="display: flex; justify-content: center">
          <b-col
            lg="4"
            md="6"
            sm="12"
            v-for="item of dataList"
            :key="item.id"
            class="wow"
            style="margin-top: 10px"
            @click="goInfo(item)"
          >
            <b-card img-top class="">
              <b-card-img
                class="mx-auto d-block rounded thumbnail"
                :src="item.imgUrl"
                :img-alt="item.name"
                :title="item.name"
              >
              </b-card-img>
              <div class="mask">
                <h3 class="title twodiandiandian">
                  {{ item.name }}
                </h3>
                <div class="subtitle twodiandiandian">
                  {{ item.summary }}
                </div>
              </div>
            </b-card>
          </b-col>
        </div>
      </b-row>

      <!-- h5 -->
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        img-height="830px"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        class="d-md-none d-block"
        style="margin-top: 30px"
        v-if="screenWidth <= 750"
      >
        <template v-for="item in dataList">
          <b-carousel-slide class="banner" :key="item.id">
            <template #img>
              <b-card img-top class="">
                <b-card-img
                  class="mx-auto d-block rounded thumbnail"
                  :src="item.imgUrl"
                  :img-alt="item.name"
                  :title="item.name"
                >
                </b-card-img>
                <div class="mask">
                  <div class="title twodiandiandian">
                    {{ item.name }}
                  </div>
                  <div class="subtitle twodiandiandian">
                    {{ item.summary }}
                  </div>
                </div>
              </b-card>
            </template>
          </b-carousel-slide>
        </template>
      </b-carousel>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import NumberGrow from "@/components/index/numberGrow";

export default {
  components: {
    NumberGrow,
  },
  props: {
    dataList: {
      type: Array,
    },
  },
  data() {
    return {
      title: "ソリューション",
      productList: [
        {
          id: 1,
          icon: require("~/static/img/about/about1.png"),
          title: "製品力",
          subtitle:
            "クラウドによる電力供給により、ワイヤレスネットワークをより",
          desc: "",
        },
        {
          id: 2,
          icon: require("~/static/img/about/about2.png"),
          title: "提案力",
          subtitle:
            "クラウドによる電力供給により、ワイヤレスネットワークをより",
        },
        {
          id: 2,
          icon: require("~/static/img/about/about3.png"),
          title: "開発力",
          subtitle:
            "クラウドによる電力供給により、ワイヤレスネットワークをよりクラウドによる電力供給により、ワイヤレスネットワークをより",
        },
      ],
      slide: 0,
      sliding: null,
    };
  },
  computed: {
    ...mapMutations(["setSubNavIndex", "setScreenWidth"]),
    ...mapState(["screenWidth"]),
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit("setScreenWidth", document.body.clientWidth);
      })();
    };
  },
  methods: {
    changeMask() {
      this.$emit("childMethod"); // 方法1
    },

    goInfo(item) {},
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.card :hover {
  transition: all 60s;
  transition-duration: 10s;
  img {
    transform: scale(1.2);
  }
}

.container {
  padding-top: 63px;
  padding-bottom: 91px;
  .main-title {
    margin-bottom: 80px;
  }
  .number-info {
    text-align: center;
    // height: 90px;
    color: #395a84;
    font-family: Source Han Sans JP;
    font-size: 48px;
    .number-span {
      font-size: 16px;
      color: #292929;
    }
    span {
      font-size: 26px;
      color: #292929;
    }
    .number {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
  .card-body {
    padding: 0;
  }
  .card {
    border: none;
    background: none;
    overflow: hidden;
  }
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  //background: rgba(#000000, 0.13);
  color: #ffffff;
  .title {
    position: absolute;
    bottom: 80px;
    left: 37px;
    right: 37px;
    height: 36px;
    line-height: 36px;
    font-weight: Bold;
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 0.6px;
    text-align: center;
  }
  .subtitle {
    position: absolute;
    bottom: 14px;
    left: 55px;
    right: 55px;
    display: flex;
    height: 58px;
    line-height: 20px;
    font-size: 16px;
    align-items: center;
    text-align: center;
  }
  .title:hover {
    transition: all 0.3s;
    // color: $red-color !important;
    // cursor: pointer;
  }
}
.number-div {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .title {
    margin-top: 15px;
  }
  .title,
  .sub-title,
  .desc {
    text-align: center;
  }
  .sub-title,
  .btn {
    display: block;
    margin: 0 auto;
  }
}
.first-letter {
  font-size: 32px;
  color: #17a2b8;
}
.bg {
  background-image: url("~@/static/img/about/about-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
@media (max-width: 750px) {
  .main-title {
    padding: 0 15px !important;
  }
  .col-sm-4 {
    width: 50% !important;
    padding: 0 15px !important;
  }
  .col-sm-12 {
    padding: 0 15px !important;
  }
  .number-div {
    justify-content: flex-start;
  }
  .container {
    padding-bottom: 30px !important;
  }
}
</style>
